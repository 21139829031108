// export const API_URL = "https://webapi.subcontractit.com/index.php/";
export const API_URL = "https://appwebapi.subcontractit.com/index.php/";
// export const API_URL = "https://testwebapi.subcontractit.com/index.php/";
// export const API_URL = "http://localhost/subcontractit/webapi/index.php/";


export const SOLAR_COMPANY = "Solar Company";
export const ROOFING_COMPANY = "Roofing Company";
export const CONTRACTOR = "Contractor";
export const SALES_ONLY_ADMIN = "Sales Only Admin";
export const SALES_ONLY_USER = "Sales Only User";
export const SALES_REPRESENTATIVE = "Sales Representative";
export const CONNECTED_CONTRACTOR = "Connected Contractors";
export const PROJECT_MANAGER = "Project Manager";
export const SUPER_ADMIN = "Super Admin";

export const SOLAR_COMPANY_TYPE_ID = "3";
export const ROOFING_COMPANY_TYPE_ID = "4";
export const SALES_ONLY_ADMIN_TYPE_ID = "6";
export const SALES_ONLY_USER_TYPE_ID = "7";
export const CONNECTED_CONTRACTOR_TYPE_ID = "8";
export const PROJECT_MANAGER_TYPE_ID = "10";

export const GOOGLE_API_KEY = "AIzaSyC1ic34twBVlFs52Ak4ALDtk0UPQ_6tWeA";
export const GOOGLE_RECAPTCHA_KEY = "6LfLkJEmAAAAAI3One6cxfbUDJtnYlaK3G_GIrDF";
